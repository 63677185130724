import { Injectable } from '@angular/core';


// Interface
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { environment } from 'environments/environment';
import { AppService } from 'app/services/app.service';

@Injectable()
export class RondasProvider {
  constructor(public http: HttpClient, private appService: AppService) { }


  public create(form: any): Observable<any> {
    const suscription = this.appService.getViewSuscription();
    return this.http.post(environment.ENDPOINT + environment.SERVICES.RONDAS, {
        ...form,
        suscription: suscription || form.suscription
    });
}


  public getList(idClient: string): Observable<any> {
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.RONDAS_LIST}/${idClient}?suscription=${this.appService.getViewSuscription()}`);
  }

  public get(rut: string): Observable<any> {
    return this.http.get(`${environment.ENDPOINT}${environment.SERVICES.RONDAS}/${rut}`);
  }

  public update(form: any): Observable<any> {
    const suscription = this.appService.getViewSuscription();
    return this.http.put(`${environment.ENDPOINT}${environment.SERVICES.RONDAS}/${form.id}`, {
      ...form,
      suscription: suscription || form.suscription
    });
  }
}
